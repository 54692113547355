import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"customer-testimonials"},[_c('p',{staticClass:"text-h3 white--text text-center pb-md-6"},[_vm._v(" Here's what our customers say about us ")]),_c('div',{staticClass:"quote-icon"},[_c(VImg,{attrs:{"src":require("@/assets/img/clientcabin/icon-quote.png")}})],1),_c(VCarousel,{attrs:{"cyclde":_vm.cycle,"hide-delimiter-background":"","height":_vm.height,"show-arrows":false},model:{value:(_vm.slide),callback:function ($$v) {_vm.slide=$$v},expression:"slide"}},_vm._l((_vm.slides),function(slide,i){return _c(VCarouselItem,{key:i},[_c(VContainer,{on:{"mouseenter":function($event){_vm.cycle = false},"mouseleave":function($event){_vm.cycle = true}}},[_c(VRow,{staticClass:"fill-height",attrs:{"align":"center","justify":"center"}},[_c(VCol,{staticClass:"px-0 pr-md-8"},[_c('p',{staticClass:"text-h5 white--text"},[_vm._v(_vm._s(slide.title))]),_c('ul',{staticClass:"py-6"},_vm._l((slide.pros),function(pro,j){return _c('li',{key:j,staticClass:"text-body-1 white--text"},[_vm._v(" "+_vm._s(pro)+" ")])}),0),_c('div',{staticClass:"white--text text-h5 font-weight-medium"},[_vm._v(" "+_vm._s(slide.author)+" ")]),_c('div',{staticClass:"position"},[_vm._v(_vm._s(slide.position))])]),_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12","md":"auto"}},[_c(VImg,{attrs:{"height":_vm.imgSize,"width":_vm.imgSize,"src":require(
                                    `@/assets/img/clientcabin/testimonials/${slide.image}`
                                ),"contain":""}})],1)],1)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }