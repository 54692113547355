<template>
    <div class="customer-testimonials">
        <p class="text-h3 white--text text-center pb-md-6">
            Here's what our customers say about us
        </p>
        <div class="quote-icon">
            <v-img src="@/assets/img/clientcabin/icon-quote.png" />
        </div>
        <v-carousel
            v-model="slide"
            :cyclde="cycle"
            hide-delimiter-background
            :height="height"
            :show-arrows="false"
        >
            <v-carousel-item v-for="(slide, i) in slides" :key="i">
                <v-container
                    @mouseenter="cycle = false"
                    @mouseleave="cycle = true"
                >
                    <v-row class="fill-height" align="center" justify="center">
                        <v-col class="px-0 pr-md-8">
                            <p class="text-h5 white--text">{{ slide.title }}</p>
                            <ul class="py-6">
                                <li
                                    v-for="(pro, j) in slide.pros"
                                    :key="j"
                                    class="text-body-1 white--text"
                                >
                                    {{ pro }}
                                </li>
                            </ul>
                            <div class="white--text text-h5 font-weight-medium">
                                {{ slide.author }}
                            </div>
                            <div class="position">{{ slide.position }}</div>
                        </v-col>
                        <v-col cols="12" md="auto" class="pa-0">
                            <v-img
                                :height="imgSize"
                                :width="imgSize"
                                :src="
                                    require(
                                        `@/assets/img/clientcabin/testimonials/${slide.image}`
                                    )
                                "
                                contain
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </v-carousel-item>
        </v-carousel>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class CustomerTestimonials extends Vue {
    slide = 0;

    cycle = true;

    slides = [
        {
            image: 'kam.png',
            title: 'Your Campaigns Catapulted Our Business to New Heights!',
            pros: [
                'Rank in the money pack for lots of different products and services he offers.',
                'Doubled his annual revenue (an additional six figures).',
                'Was forced to hire more help in the form of 2 new therapists to help meet the demand.',
                'Went from one bed at half capacity to 3 beds at near full capacity.'
            ],
            author: 'Kam',
            position: 'Physiotherapist'
        },
        {
            image: 'edward.jpg',
            title: 'Your Expert Campaigns Transformed Our Luxury Business!',
            pros: [
                'High quality leads for a luxury/design build business.',
                'Doubled his annual revenue (an additional six figures).',
                'Receive lots of traffic for keyword searches in Google.',
                'Got over 340,000 GMB views in a 3 month period.'
            ],
            author: 'Edward',
            position: 'Manager'
        },
        {
            image: 'peter.jpg',
            title: 'Thanks to Your Campaigns, We Flourish Across Multiple Locations!',
            pros: [
                'Get in the money pack across multiple locations.',
                'Get his time back so he can focus on being the CEO instead of working in the business.',
                'Receives organic traffic from having high visibility in Google.'
            ],
            author: 'Peter',
            position: 'Business Owner'
        }
    ];

    get height() {
        return this.$vuetify.breakpoint.smAndDown ? 'auto' : 400;
    }

    get imgSize() {
        return this.$vuetify.breakpoint.smAndDown ? '100%' : 260;
    }
}
</script>

<style lang="scss" scoped>
.customer-testimonials::v-deep {
    max-width: 1100px;
    overflow: visible;

    .position {
        font-size: 23px;
    }

    .v-item--active.v-btn--active.v-btn--icon {
        color: $darker-blue;
    }

    .v-btn--icon {
        color: $grey-medium-light;
    }

    .quote-icon {
        width: 55px;
        height: 60px;
        border-bottom: 5px solid $primary-color;
    }
}
</style>
